<template>
    <v-card width="100%" height="100%" v-if="loaded">
        <v-tabs v-model="currentTab" show-arrows centered>
            <!-- <v-tabs-slider color="yellow"></v-tabs-slider> -->

            <v-tab v-for="(item,index) in categories" :key="index">
                {{ item.Name }}
            </v-tab>
        </v-tabs>
        <v-row v-if="filteredItems.length === 0" style="height:50%"
            class="ma-2"
            justify="center"
            align-content="center"
        >
            <v-alert
                border="right"
                colored-border
                type="error"
                elevation="2"
                >
                Nessun risultato
            </v-alert>
        </v-row>

        <FilesList
            v-else
            :files="filteredItems"
            :scrollerHeight="scrollerHeight - marginTop"
            @selected="selectFile"
            @playcontent="playContent"
        ></FilesList>

        <ShowContent
            v-if="showContent"
            @close="showContent = !showContent"
            :media="mediaObject"
        ></ShowContent>

    </v-card>
</template>

<script>
import * as snackBar from "../../store/actions/snackbar";

import Comunicazione from '../../viewmodels/comunicazione.js'

export const FilesList = () => import("./FilesList");
export const ShowContent = () => import("../core/ShowContent");

import { promeFilePlayFile } from "../../utils/api";
import { compareDate } from "../../utils/utils";

import { mapActions, mapMutations, mapState } from "vuex";
import * as promeFilesActions from "../../store/actions/promeFile";
import * as promeCategoriesActions from "../../store/actions/promeCategory";
import * as appActions from "../../store/actions/app";
import * as promoActions from '../../store/actions/promo'
import * as farmPromosActions from '../../store/actions/farmPromos'

export default {
    props: ["id", "scrollerHeight"],

    data: () => ({
        // scrollerHeight: 0,
        hidden: false,
        showContent: false,
        mediaObject: {}
    }),

    components: {
        FilesList,
        ShowContent
    },

    computed: {
        ...mapState({
            promeCategories: state => state.promeCategoryModule.categories,
            promeFiles: state => state.promeFileModule.promeFiles,
            loading: state => state.promeFileModule.loading,
            loaded: state => state.promeFileModule.loaded,
            error: state => state.promeFileModule.error,
            promeFileFilter: state => state.filterModule.promeFileFilter,
            currentTabPosition: state => state.appModule.promeFileCurrentTab,
            canAddPromos: state => state.userModule.privileges.addPromo
        }),

        currentTab: {
            get: function() {
                return this.currentTabPosition
            },
            set: function(newValue) {
                this.setTabPosition(newValue)
            }
        },

        categories() {
            var cats = JSON.parse(JSON.stringify(this.promeCategories))
            cats.unshift({ Name: "Tutte", Id: '0'})
            return cats
        },

        filteredItems() {
            // console.log(this.promeFiles)
            return this.promeFiles.filter(file => {

                const expired = compareDate(file.Al) === 1;
                const f2 = !expired

                const f1 = this.promeFileFilter.expired && expired;

                const f5 = this.currentTab === 0 ? true : file.Name === this.promeCategories[this.currentTab - 1].Name

                const f6 =
                    this.promeFileFilter.text.length === 0
                        ? true
                        : file.NomeFile.toLowerCase().indexOf(
                              this.promeFileFilter.text
                          ) !== -1;

// if (file.NomeFile.indexOf("CRUDO") !== -1) {
//     console.log(new Date(file.Dal).setHours(0, 0, 0, 0), new Date(this.promeFileFilter.from).setHours(0, 0, 0, 0))
// }

                const f7 = this.promeFileFilter.fromEnabled
                    ? new Date(file.Dal).setHours(0, 0, 0, 0) >= new Date(this.promeFileFilter.from).setHours(0, 0, 0, 0)
                    : true;

                const f8 = this.promeFileFilter.toEnabled
                    ? new Date(file.Al).setHours(0, 0, 0, 0) <= new Date(this.promeFileFilter.to).setHours(0, 0, 0, 0)
                    : true;

                return (f1 || f2 ) && f5 && f6 && f7 && f8;
            });
            // // console.log(this.farmsPromos)
            // let dt;
            // dt = new Date();
            // dt.setHours(0, 0, 0, 0);
            // return this.farmsPromos.filter(promo => {
            //     const expired = compareDate(promo.FineValidita) === 1;
            //     const toPublish = compareDate(promo.InizioValidita) === -1;
            //     const deleted = promo.Action === 1;
            //     const suspended = promo.Sospeso === true;
            //     const active =
            //         !expired &&
            //         !toPublish &&
            //         !deleted &&
            //         !suspended &&
            //         promo.action !== 1;

            //     const f1 = this.promoFilter.expired && expired;
            //     const f2 = this.promoFilter.deleted && deleted;
            //     const f3 = this.promoFilter.toPublish && toPublish;
            //     const f4 = this.promoFilter.suspended && suspended;
            //     const f5 = this.promoFilter.active && active;
            //     const f6 =
            //         this.promoFilter.text.length === 0
            //             ? true
            //             : promo.Titolo.toLowerCase().indexOf(
            //                   this.promoFilter.text
            //               ) !== -1;

            //     return (f1 || f2 || f3 || f4 || f5) && f6;
            // });
        },

        marginTop() {
            return "48";
        }
    },

    methods: {
        ...mapActions({
            showSnackbar: snackBar.SNACKBAR_SHOW,
            getPromeFiles: promeFilesActions.PROMEFILE_GETFILES,
            getPromeCategories: promeCategoriesActions.PROMECATEGORY_GETCATEGORIES,
            createPromo: promoActions.PROMO_NEWPROMO,
            removePromoFile: promeFilesActions.PROMEFILE_REMOVEFILE
        }),

        ...mapMutations({
            setTabPosition: appActions.APP_SETPROMEFILES_CURRENTTAB,
            appendFarmsPromo: farmPromosActions.FARMPROMOS_APPEND,
        }),

        // onResize() {
        //     this.$nextTick(function() {
        //         const vw = Math.max(
        //             document.documentElement.clientWidth || 0,
        //             window.innerWidth || 0
        //         );
        //         const vh = Math.max(
        //             document.documentElement.clientHeight || 0,
        //             window.innerHeight || 0
        //         );
        //         this.scrollerHeight = vh - this.$vuetify.application.top - 72;
        //         /*                 console.log(vw, vh);
        //         console.log(this.$vuetify); */
        //     });
        // },
        selectFile(file) {
            if ( !this.canAddPromos ) {
                this.showSnackbar('Non hai i privilegi per inserire nuove Comunicazioni!')
                return
            }
            const comm = new Comunicazione()
            let data = comm.setFarm(this.$route.params.id)
            data = comm.setStores(file.Stores)
            const type = file.NomeFile.indexOf('MP3') !== -1 || file.NomeFile.indexOf('M4A') !== -1 ? 'audio' : 'video'
            data = comm.setFile(`${promeFilePlayFile}/${file.Id}`, file.NomeFile, type)
            data = comm.setFromTo(file.Dal, file.Al)

            console.log(file, data);
            // console.log(this.$route.params.storeId)
            this.$formdialog.open({
                    type: '',
                    title: 'Aggiungi Comunicazione',
                    data: data,
                    formName: 'AddPromo',
                    fullScreen: true
                    })
                .then( async (response) => {
                    this.$formdialog.showProgress(true)
                    console.log(response)
                    const result = await this.createPromo({ farmId: this.id, promo: response })
                    const removed = await this.removePromoFile(file.Id)
                    this.appendFarmsPromo(result)
                    // this.appendStorePromo(result)
                    console.log(result)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.$formdialog.showProgress(false)
                    this.$formdialog.destroy()
                })
        },

        playContent(item) {
            console.log('fai play', item)
            this.mediaObject = {
                type: 0,
                src: `${promeFilePlayFile}/${item.Id}`,
                name: item.NomeFile,
                }
            this.showContent = true
        }
    },

    mounted() {
        this.$nextTick(function() {
            this.getPromeFiles(this.id);
            this.getPromeCategories(this.id);
        });
    }
};
</script>

<style scoped></style>
